
export default {
	name: "CoreBlockContainerAccordion",
	props: {
		settings: {
			type: Object,
			required: true,
		},
		isShowingContents: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		accordionLabelTag() {
			return this?.settings?.accordionLabelTag || 'h5'
		},
		accordionLabel() {
			return this.settings.accordionText || "Accordion";
		},
	},
};
