
import Vue from "vue";
export default {
	name: "CoreBlockContainer",
	props: {
		index: {
			type: Number,
			default: 0,
		},
		nestedLevel: {
			type: Number,
			default: 0,
		},
		settings: {
			type: Object,
			required: true,
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			activeChildId: null,
			isShowingContents: true,
			mainSectionStyles: {
				display: "flex",
				width: "100%",
				minWidth: "100%",
				marginLeft: "0%",
				transition: "margin-left .2s",
				alignItems: "stretch",
				justifyContent: "center",
				gap: "24px",
			},
			carouselInterval: null,
		};
	},
	computed: {
		activeChildIndex() {
			if (!this.activeChildId) {
				return null;
			}
			return this.blockChildren.findIndex((childSettings) => {
				return childSettings.id === this.activeChildId;
			});
		},
		blockChildren() {
			return this.settings?.blocks || [];
		},
		blockChildrenCount() {
			return this.blockChildren.length;
		},
		blockId() {
			return this.settings.id;
		},
		blockVariantLayout() {
			return this.settings?.variants?.layout || "rows";
		},
		isSlim() {
			return (
				this.settings?.variants &&
				this.settings?.variants["child-width"] === "slim"
			);
		},
		blockSectionMainStyles() {
			if (this.hasSlideControls) {
				return this.mainSectionStyles;
			}
			return null;
		},
		nestedLevelNext() {
			return this.nestedLevel + 1;
		},
		isHorizontal() {
			return (
				[
					"columns",
					"grid",
					"carousel",
					"tabbed",
					"toggle",
					"carousel-hero",
				].indexOf(this.blockVariantLayout) >= 0
			);
		},
		/*
			todo the childBlockComponent property is only useful for the builder
			so it should be separated from this block's logic
		*/
		childBlockComponent() {
			if (this.isEditable) {
				return "ForgeControlBlock";
			}
			return "CoreBlock";
		},
		hasPageControls() {
			return (
				["carousel", "tabbed", "toggle", "carousel-hero"].indexOf(
					this.blockVariantLayout
				) >= 0
			);
		},
		hasSlideControls() {
			return (
				["carousel", "carousel-hero"].indexOf(
					this.blockVariantLayout
				) >= 0
			);
		},
		hasAccordionControls() {
			return this.settings.hasAccordion;
		},
		hasToggleControls() {
			return ["toggle"].indexOf(this.blockVariantLayout) >= 0;
		},
		showTabs() {
			return (
				this.isShowingContents &&
				this.hasPageControls &&
				!this.hasSlideControls &&
				!this.hasToggleControls
			);
		},
		enableCarouselMode() {
			return this.settings.enableCarouselMode;
		},
		autoSwipeMode() {
			return this.settings.autoSwipeMode;
		},
		intervalSwipeTime() {
			return this.settings.intervalSwipeTime;
		},
	},
	watch: {
		blockChildren: function () {
			this.handleResizeMainSection();
		},
		enableCarouselMode() {
			if (!this.enableCarouselMode) {
				if (this.carouselInterval) {
					clearInterval(this.carouselInterval);
				}
				return;
			}

			if (this.autoSwipeMode) {
				this.generateSwipeInterval();
			}
		},
		autoSwipeMode: function () {
			if (!this.autoSwipeMode) {
				clearInterval(this.carouselInterval);
				return;
			}
			this.generateSwipeInterval();
		},
		intervalSwipeTime: function () {
			if (this.carouselInterval) {
				clearInterval(this.carouselInterval);
			}
			this.generateSwipeInterval();
		},
	},
	mounted() {
		if (this.hasSlideControls) {
			const swipeArea = this.$el;
			let touchstartX = 0;
			let touchstartY = 0;
			let touchendX = 0;
			let touchendY = 0;
			const threshold = 50; // minimum distance required to register a swipe

			swipeArea.addEventListener(
				"touchstart",
				(event) => {
					touchstartX = event.changedTouches[0].screenX;
					touchstartY = event.changedTouches[0].screenY;
				},
				false
			);

			swipeArea.addEventListener(
				"touchend",
				(event) => {
					touchendX = event.changedTouches[0].screenX;
					touchendY = event.changedTouches[0].screenY;
					const deltaX = touchendX - touchstartX;
					const deltaY = touchendY - touchstartY;
					if (
						Math.abs(deltaX) > threshold &&
						Math.abs(deltaY) < threshold
					) {
						this.handleSwipe(deltaX > 0 ? "right" : "left");
					}
				},
				false
			);
		}

		// setInterval to swipe automatically if the carousel and autoSwipe mode are enabled
		if (this.isCarouselModeEnabled() && this.autoSwipeMode) {
			this.generateSwipeInterval();
		}

		window.addEventListener("resize", this.handleResizeMainSection);
		this.handleResizeMainSection();
	},
	created() {
		if (this.hasPageControls) {
			if (this.blockChildren.length) {
				// start with the first tab open
				this.setActiveChild(this.blockChildren[0].id);
			}
		}
		if (this.hasSlideControls) {
			// get autoscroll going if selected
			// if (this.settings.autoscroll) {
			// 	console.info("Autoscroll activated!");
			// }
		}
		if (this.hasAccordionControls) {
			// Note: the disclosure accordion in the footer needs to stay open by default
			this.isShowingContents = this.settings.defaultOpen || false;
		}
	},
	beforeDestroy() {
		// Clean listeners and intervals here to avoid overloading
		window.removeEventListener("resize", this.handleResizeMainSection);
		if (this.carouselInterval) {
			clearInterval(this.carouselInterval);
		}
	},
	/*
		todo separate the builder behaviors below (insert/select)
		from the block's concerns,
		since they only apply in the builder
	*/
	methods: {
		toggleContents() {
			this.isShowingContents = !this.isShowingContents;
		},
		setActiveChild(childId) {
			Vue.set(this, "activeChildId", childId);
			this.handleResizeMarginLeft();
		},
		showNextChild() {
			let newIndex = this.blockChildren.findIndex((childSettings) => {
				return childSettings.id === this.activeChildId;
			});
			newIndex++;
			if (newIndex >= this.blockChildren.length) {
				newIndex = 0;
			}
			if (this.blockChildren[newIndex]) {
				this.setActiveChild(this.blockChildren[newIndex].id);
			}
		},
		showPreviousChild() {
			let newIndex = this.blockChildren.findIndex((childSettings) => {
				return childSettings.id === this.activeChildId;
			});
			newIndex--;
			if (newIndex < 0) {
				newIndex = this.blockChildren.length - 1;
			}
			if (this.blockChildren[newIndex]) {
				this.setActiveChild(this.blockChildren[newIndex].id);
			}
		},
		handleSwipe(direction) {
			if (direction === "right") {
				this.showPreviousChild();
			} else {
				this.showNextChild();
			}
		},
		handleResizeMainSection() {
			this.mainSectionStyles.width = `${
				this.blockChildrenCount * this.blockChildrenWidth()
			}%`;
			this.handleResizeMarginLeft();
		},
		handleResizeMarginLeft() {
			this.mainSectionStyles.marginLeft = `${
				-this.activeChildIndex * this.blockChildrenWidth()
			}%`;
		},
		moveCarouselElement(itemIndex) {
			this.mainSectionStyles.marginLeft = `${
				-itemIndex * this.blockChildrenWidth()
			}%`;
		},
		showSlide() {
			return (
				this.isShowingContents &&
				this.hasPageControls &&
				this.hasSlideControls &&
				this.blockChildrenCount * this.blockChildrenWidth() > 100 &&
				this.isCarouselModeEnabled()
			);
		},
		blockChildrenWidth() {
			if (this.isSlim) {
				if (process.client) {
					if (
						window.matchMedia("(min-width: 992px)").matches &&
						this.blockVariantLayout != "carousel-hero"
					) {
						return 33.3;
					}
				}
			}
			return 100;
		},
		isCarouselModeEnabled() {
			// This method only useful if used if the layout is carousel-hero, otherways always return true.
			if (this.blockVariantLayout != "carousel-hero") {
				return true;
			}

			if (this.enableCarouselMode) {
				return true;
			}

			return false;
		},
		generateSwipeInterval() {
			this.carouselInterval = setInterval(
				function () {
					this.handleSwipe("left");
				}.bind(this),
				this.intervalSwipeTime > 300 ? this.intervalSwipeTime : 300
			);
		},
	},
};
